header {
  position: sticky;
  z-index: 1000;
  top: 0;
}
.header-container {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

    .logo-link {
      display: inline-block;
      margin-left: 30px;
      @media screen and (max-width: 540px) {
        margin-left: 0;
      }
      @media screen and (max-width: 400px) {
        margin-left: 10px;
      }
    }
    .logo-link img {
      @include verticalCenter;
      width: 46px;
      height: auto;
      @media screen and (max-width: 700px) {
        width: 35px;
      }
    }


nav {
margin-right: 30px;
@media screen and (max-width: 540px) {
  margin-right: 10px;
}
  ul {
    display: flex;
    font-size: 1.4rem;
    width: 100%;
    gap: 20px;

    li {
      color: $color-7;
      transition: 0.2s;
      cursor: pointer;
      @media screen and (max-width: 700px) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: 540px) {
        font-size: 1rem;
      }

      a {
        color: $color-7;
        text-decoration: none;
        padding: 10px;
        @media screen and (max-width: 700px) {
          padding: 0 0 10px;
        }          
        &:hover {
          color: $color-6;
        }
      }
    }

    .active {
      position: relative;

      &::after {
        content: "";
        height: 4px;
        width: 0;
        background: $color-1;
        position: absolute;
        border-radius: 10px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-animation: anim 0.3s ease forwards;
        animation: anim 0.6s ease forwards;

        @-webkit-keyframes anim {
          to {
            width: 100%;
          }
        }
        @keyframes anim {
          to {
            width: 100%;
          }
        }
      }
    }
  }
}

.menu-icon {
  display: none; 
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  background: $color-5;
}

@media screen and (max-width: 400px) {
  .menu-icon {
    display: block; 
    cursor: pointer;
    padding: 10px;
    background: $color-3;
  }

  .menu-icon .line {
    width: 25px;
    height: 3px;
    background-color: $color-7;
    margin: 5px 0 0 60px;
    transition: all 0.3s ease;
  }

  .menu-icon .line.open:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .menu-icon .line.open:nth-child(2) {
    opacity: 0;
  }

  .menu-icon .line.open:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  nav ul {
    display: none; 
  }

  nav.show ul {
    display: block;
    background: $color-3;
    width: 140px;
    text-align: end;
    padding-right: 35px;
  }
  nav.show {
    display: block; 
    background: $color-3;
    margin-top: 155px;
    margin-right: -25px;
  }
  nav.show li {
    margin-bottom: 20px;
  }

}
}