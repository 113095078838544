@font-face {
  font-family: "font-title";
  src: url(../assets/fonts/AllertaStencil.ttf);
}
@font-face {
  font-family: "font-text";
  src: url(../assets/fonts/Exo2-SemiBold.ttf);
}

@font-face {
  font-family: "font-light";
  src: url(../assets/fonts/Exo2-Light.ttf);
}

$font-title: "font-title", sans-serif;
$font-text: "font-text", "sans-serif";
$font-light: "font-light", "sans-serif";

$color-1: #77B5B6;
$color-2: #A8D0CF;
$color-3: #DEEFEF;
$color-4: #FADDB3;
$color-5: #EFE8DB;
$color-6: #BFA7AA;
$color-7: #8699AE;
$color-8: #45678c;

$nbHexa: 6;
$nbHexa900px: 4;
$nbHexa600px: 2;
$gap: 2vw;
$size: calc(min(100vw, 1440px) / $nbHexa - $gap);
$size900px: calc(120vw / $nbHexa - $gap);
$size600px: calc(180vw / $nbHexa - $gap);

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin socialIcon {
  width: 56px;
  height: 50px;
  display: inline-block;
  background-position: center;
  background-size: cover;
}

@mixin menuTitle {
  min-height: calc(100vh - 150px);
  padding-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .menu-title {
      margin-bottom: 50px;
      width: 25%;
      @media screen and (max-width: 1200px) {
        margin-bottom: 0;
        width: 35%;
      }
      @media screen and (max-width: 900px) {
        margin-bottom: 0;
        width: 45%;
      }
      @media screen and (max-width: 430px) {
        margin-bottom: 0;
        width: 60%;
      }
      img {
          width: 100%;
          height: auto;
          display: block;
      }
  }
}

*, *:after, *:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scrollbar-color: $color-1 $color-3;
  scrollbar-width: thin;
}

body {
  font-family: $font-text;
  overflow-x: hidden;
  @media only screen and (min-width: 1440px) {
    body {
      max-width: 1440px; /* Limite la largeur de la page à 1440px */
      margin: 0 auto; /* Centre le contenu horizontalement */
    }
  }
}

#root {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  hyphens: auto;
}

header, footer {
  background: $color-3;
}

main {
  background: $color-2
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.common-container {
  background: url(../assets/background/bg.webp) center/cover;
  background-repeat: repeat-x;
}

li {
  list-style-type: none;
}

p {
  hyphens: none;
}