.footer-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  
    .footer-logo-container {
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        max-width: 300px;
        
        .linkedin-icon {
            background: url('../../assets/footer/logo-linkedin.webp') center/cover;
            @include socialIcon
        }
        .linkedin-icon:hover {
            background-image: url('../../assets/footer/logo-linkedin-2.webp');
        }
        .git-icon {
            background: url('../../assets/footer/logo-git.webp') center/cover;
            @include socialIcon
        }
        .git-icon:hover {
            background-image: url('../../assets/footer/logo-git-2.webp');
        }
        .discord-icon {
            background: url('../../assets/footer/logo-discord.webp') center/cover;
            @include socialIcon
        }
        .discord-icon:hover {
            background-image: url('../../assets/footer/logo-discord-2.webp');
        }
        .mail-icon {
            background: url('../../assets/footer/logo-mail.webp') center/cover;
            @include socialIcon
        }
        .mail-icon:hover {
            background-image: url('../../assets/footer/logo-mail-2.webp');
        }
        .media-icon {
            transition: 0.3s;
        }
        
    }
    
    p {
    font-size: 0.8rem;
    color: $color-7;
    }
}