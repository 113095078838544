.contact-container {
    @include menuTitle;
    
    .contact-content {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        width: 100%;
        padding: 0 5%;
        @media screen and (max-width: 900px) {
            grid-template-columns: none;
            justify-content: center;
        }

        .contact-left {
            @media screen and (max-width: 900px) {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        p {
            color: $color-8;
            width: 80%;
            margin: 0 auto;
            text-align: justify;
            margin-bottom: 20px;
        }

        img {
            width: 80%;
            margin: auto;
            display: block;
            @media screen and (max-width: 900px) {
                width: 60%;
                margin-bottom: 20px;
            }
        }

        form {
            display: grid;
            grid-template-areas: 
            "ln fn"
            "mail mail"
            "subject subject"
            "message message"
            "submit submit"
            ;
            gap: 5px;
            @media screen and (max-width: 900px) {
                margin: 0 auto;
                width: 90%;;
            }
            .lastname-container {
                grid-area: ln;
            }
            .firstname-container {
                grid-area: fn;
            }
            .email-container {
                grid-area: mail;
            }
            .subject-container {
                grid-area: subject;
            }
            .message-container {
                grid-area: message;
                #text-area {
                    resize: none;
                    height: 150px;
                }
            }
            input[type="submit"] {
                grid-area: submit;
                background: $color-7;
                color: white;
                font-size: 1.3rem;
                cursor: pointer;
                width: 60%;
                margin: 20px auto 20px;
                transition: 0.3s;
                @media screen and (max-width: 600px) {
                    font-size: 1rem;
                }
                @media screen and (max-width: 430px) {
                    width: 100%;
                }
            }
            #btn-submit:hover {
                transform: scale(1.1);
            }
            #btn-submit:active {
                background: $color-1;
            }
            label {
                padding-bottom: 5px;
                color: $color-8;
                margin-left: 5px;
                margin-bottom: 5px;
                display: block;
            }
            input, textarea {
                width: 95%;
                padding: 10px;
                border-radius: 10px;
                border: 2px solid $color-7;
                font-family: $font-text;
                @media screen and (max-width: 900px) {
                    width: 100%;
                }
            }
            input[type="text"]:focus, textarea:focus {
                background: transparent;
                border: 2px solid $color-1;
                outline: none;
                font-family: $font-text;
            }
            #lastname, #firstname {
                width: 90%;
                @media screen and (max-width: 900px) {
                    width: 100%;
                }
            }
            .error-message {
                display: block;
                font-size: 0.8rem;
                transition: opacity 0.3s; 
                color: red;
                visibility: hidden; 
                margin-top: 5px;
                margin-left: 5px;
            }
            
            .error-message.show {
                visibility: visible; 
                opacity: 1; 
            }
            .form-common {
                margin-top: 10px;
            }
        }
    }
}