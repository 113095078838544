.home-container {
    min-height: calc(100vh - 150px);
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 20px;
    @media screen and (max-width: 900px) {
        grid-template-columns: none;
        justify-content: center;
    }

    
    .main-left {
        @include verticalCenter;
        flex-direction: column;

        .logo-title {

            .logo-orphynou {
                width: 80%;
                margin-left: 20%;
                @media screen and (max-width: 900px) {
                    margin-left: 0;
                }
                @media screen and (max-width: 450px) {
                    width: 60%;
                }
            }

            @media screen and (max-width: 900px) {
                text-align: center;;
            }
        }

        .text-left {
            display: flex;
            flex-direction: column;
            text-wrap: nowrap;
            line-height: 1.5;
            font-family: $font-title;
            font-size: 2.5rem;
            @media screen and (max-width: 1280px) {
                font-size: 2rem;
            }
            @media screen and (max-width: 900px) {
                align-items: center;
            }
            @media screen and (max-width: 450px) {
                font-size: 1.5rem;
            }
            .linex {
                @media screen and (max-width: 900px) {
                    margin-left: 0;
                }
            }
            .line1 {
                font-size: 3.5rem;
                margin-left: 20%;
                line-height: 2;
                @media screen and (max-width: 1280px) {
                    font-size: 3rem;
                }
                @media screen and (max-width: 900px) {
                    margin-left: 0;
                }
                @media screen and (max-width: 450px) {
                    font-size: 2rem;
                }
            }
            .line2, .line4 {
                margin-left: 20%;
                @media screen and (max-width: 900px) {
                    margin-left: 0;
                }
            }
            .line3, .line5 {
                margin-left: 50%;
                @media screen and (max-width: 900px) {
                    margin-left: 0;
                }
            }
            .green-txt {
                color: $color-1;
            }
            .blue-txt {
                color: $color-7;
            }
        }
        
    }

    .main-right {
        display: flex;
        align-items: center;
        @media screen and (max-width: 900px) {
            justify-content: flex-start;
        }
        picture {
            display: flex;
            img {
                width: 90%;
                height: auto;
            }
            @media screen and (max-width: 900px) {
                justify-content: center;
            }
        }
    }
}