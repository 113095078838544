.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  animation: fadeOut 0.5s ease-in-out forwards;
  .modal-content {
    background-color: $color-3;
    width: 90%;
    max-width: 1280px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 80vh;
    overflow-y: auto;
    position: relative;
    .close-button {
      position: absolute;
      top: 2px;
      right: 2px;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 20px;
      color: $color-7;
    }
  }
}

.modal-overlay.open {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
  
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}