.about-container {
    @include menuTitle;

    .about-content {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 90%;
        @media screen and (max-width: 900px) {
            grid-template-columns: none;
            justify-content: center;
        }
        .about-left {
            width: 100%;
            @include verticalCenter;
            picture {
                @include verticalCenter;
            }
            img {
                width: 100%;
                @media screen and (max-width: 900px) {
                    width: 70%;
                    margin-bottom: 20px;
                }
                @media screen and (max-width: 500px) {
                    width: 90%;
                    margin-bottom: 20px;
                }
            }
        }
        .about-right {
            width: 95%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 35px;
            @media screen and (max-width: 900px) {
                margin: 0 auto;
            }
            p {
                text-align: justify;
                margin-bottom: 20px;
                font-size: 1.3rem;
                color: $color-8;
                @media screen and (max-width: 900px) {
                    font-size: 1.1rem;
                }
                @media screen and (max-width: 600px) {
                    font-size: 1rem;
                }
            }
        }
    }
    .langage-container {
        display: flex;
        flex-direction: column;
        width: 90%;
        @include verticalCenter;
        h3 {
            @include verticalCenter;
            margin: 30px auto;
            img {
                height: 50px;
                @media screen and (max-width: 900px) {
                    height: 55px;
                }
                @media screen and (max-width: 600px) {
                    height: 50px;
                }
                @media screen and (max-width: 430px) {
                    height: 45px;
                }
            }
        }
        .langage-icons {
            @include verticalCenter;
            margin-bottom: 30px;
            img {
                width: 15%;
                @media screen and (max-width: 900px) {
                    width: 18%;
                }
                
            }
        }
    }
}