.modal-container {
    display: flex;
    flex-direction: column;

    .modal-title {
        width: 60%;
        margin: 0 auto 20px;
        @media screen and (max-width: 900px) {
            width: 80%;        
        }
        @media screen and (max-width: 600px) {
            width: 90%;        
        }
        img {
            width: 100%;
        }
    }
    

    .modal-main {
        display: flex;
        @media screen and (max-width: 900px) {
            flex-direction: column;  
            align-items: center;          
        }
        .modal-main-left {
            width: 50%;
            @include verticalCenter;
            @media screen and (max-width: 900px) {
                width: 100%;        
            }
            img {
                width: 80%;
                @media screen and (max-width: 900px) {
                    width: 70%;        
                }
            }
        }

        .modal-main-right {
            width: 50%;
            display: flex;
            flex-direction: column;
            text-align: justify;
            @media screen and (max-width: 900px) {
                width: 100%;        
            }
            .modal-tag {
                margin-top: 20px;
            }
            .modal-desc {
                    padding-right: 10px;
                .intro-work {
                    padding: 30px;
                }
                margin-top: 20px;
                h3 {
                    margin: 20px 0 5px;
                }
                .font-light {
                    font-family: $font-light;
                }
                ul {
                    margin-left: 30px;
                    li {
                    list-style-type: disclosure-closed;
                }
                }
                
            }
        }
    }

    .screen-title {
        margin: 20px auto 0;
        width: 30%;
        @media screen and (max-width: 900px) {
            width: 40%;        
        }
        @media screen and (max-width: 600px) {
            width: 50%;        
        }
        img {
            width: 100%;
        }
    }
    .modal-screen {
        display: flex;
        margin-left: -15px; /* gutter size offset */
        width: auto;
    
        .modal-screen-grid {
            padding-left: 15px;

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 20px;
                padding-bottom: 10px;
            }
        }
    }
}

    //     .modal-screen {
    //     display: grid;
    //     grid-template-columns: 50% 50%;
    //     margin: 30px auto;
    //     grid-gap: 10px;
    //     @media screen and (max-width: 900px) {
    //         grid-template-columns: none;      
    //     }
    //     img {
    //         width: 100%;
    //         object-fit: cover;
    //         border-radius: 20px;
    //     }
    // }