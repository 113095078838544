.works-container {
    @include menuTitle;
}


.hexa-container {
    margin-top: 50px;
	padding-right: 7vw;
	filter: drop-shadow(10px 10px 3px $color-7);
    @media only screen and (max-width: 1440px) {
        padding-right: 13vw;
    }
    @media only screen and (max-width: 600px) {
        padding-right: 30vw
    }
    section {
        margin: calc($size * .5) auto 100px;
        width: calc($size * calc($nbHexa - 1));
        display: grid;
        grid-template-columns: repeat($nbHexa, 1fr);
        grid-gap: $gap;
        @media only screen and (max-width: 1440px) {
            width: calc($size900px * calc($nbHexa900px - 1));
            grid-template-columns: repeat($nbHexa900px, 1fr);
        }
        @media only screen and (max-width: 600px) {
            width: calc($size600px * calc($nbHexa600px - 1));
            grid-template-columns: repeat($nbHexa600px, 1fr);
        }
    }
}

.modal-open {
    overflow: hidden;
  }