.modal-tag {
    margin: 0 auto;

    .tag-ul {
      @include verticalCenter;
      flex-wrap: wrap;
      width: 100%;
      gap: 20px;
      @media screen and (max-width: 768px) {
        overflow: auto;
      }

      .tag-li {
        border: 2px solid $color-7;
        background: #f3f9f9;
        width: 100px;
        text-align: center;
        border-radius: 10px;
        padding: 5px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 768px) {
          
        }
        @media screen and (max-width: 430px) {
          font-size: 0.675rem;
        }
      }
    }
}