article {
    width: $size;
    height: calc($size / 1.1111111);
    clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    margin-right: calc($size / 2);
    color: #fff;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1440px) {
        width: $size900px;
        height: calc($size900px / 1.1111111);
      }
      @media screen and (max-width: 600px) {
        width: $size600px;
        height: calc($size600px / 1.1111111);
      }
}

article:nth-child(2n) {
    margin: calc($size * -.5) calc($size * -.25) 0 calc($size * -.75);
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    cursor: pointer;
    transition: 0.3s;
}
.image-container:hover {
    transform: scale(1.1);}

.image-container img,
.img-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.image-container img {
    width: 100%;
    height: 100%;
}

.img-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.3s;
}

article:hover .img-overlay {
    opacity: 1;
}
